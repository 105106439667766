import * as React from 'react';
import { useState } from 'react';
import { Divider, Grid } from '@mui/material/';
import '../styles/team.css';
import ListComponent from '../Components/ListComponent';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SchoolIcon from '@mui/icons-material/School';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import '../styles/app.css';
import { Button } from 'semantic-ui-react';
export default function Team() {
  const [expandedItemIndex, setExpandedItemIndex] = useState<number>(0);
  const toggleExpand = (index: number) => {
    if (expandedItemIndex === index) {
      setExpandedItemIndex(0);
    } else {
      setExpandedItemIndex(index);
    }
  };

  return (
    <Grid>
      <Grid
        item
        md={12}
        xs={12}
        style={{ textAlign: 'center' }}
        className='title'
        id='team_section'
      >
        Nasz zespół
      </Grid>

      {employees
        .sort((a, b) => a.id - b.id)
        .map((employee) => (
          <Grid
            container
            key={employee.id}
            xs={12}
            className='team_container'
            sx={{
              marginTop: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid xs={12} md={6} sx={{ display: 'flex', position: 'realtive' }}>
              <div className='svg-container'>
                <svg
                  id='sw-js-blob-svg'
                  viewBox='0 0 100 100'
                  xmlns='http://www.w3.org/2000/svg'
                  className='blob'
                >
                  <defs>
                    <linearGradient
                      id='sw-gradient'
                      x1='0'
                      x2='1'
                      y1='1'
                      y2='0'
                    >
                      <stop
                        id='stop1'
                        stop-color='hsla(165, 100%, 35%, 0.6)'
                        offset='0%'
                      ></stop>
                      <stop
                        id='stop2'
                        stop-color='hsla(180, 68%, 23%, 1)'
                        offset='100%'
                      ></stop>
                    </linearGradient>
                  </defs>
                  <path
                    fill='url(#sw-gradient)'
                    d='M14,-24.6C19.9,-20.8,27.6,-20.6,33.3,-17.1C38.9,-13.6,42.3,-6.8,43.5,0.7C44.7,8.2,43.7,16.4,40,23.3C36.3,30.1,30.1,35.7,23,35.2C15.9,34.8,7.9,28.3,1,26.6C-6,25,-12,28.1,-17.3,27.5C-22.5,26.9,-27,22.5,-28.9,17.3C-30.8,12.1,-30.2,6.1,-28.9,0.8C-27.5,-4.5,-25.4,-9,-23.7,-14.5C-21.9,-20,-20.5,-26.5,-16.6,-31.4C-12.7,-36.3,-6.3,-39.6,-1.1,-37.6C4,-35.6,8.1,-28.3,14,-24.6Z'
                    width='100%'
                    height='100%'
                    transform='translate(50 50)'
                    stroke-width='0'
                  ></path>
                </svg>
                <img
                  src={employee.imageUrl}
                  className='person_image'
                  alt={employee.name}
                  title={employee.name}
                />
              </div>
            </Grid>
            <Grid
              xs={12}
              md={6}
              className={`team_content_container ${
                expandedItemIndex === employee.id ? 'expanded' : ''
              }`}
            >
              <Grid className='team_signatures'>{employee.name}</Grid>
              <Grid className='team_position'>{employee.position}</Grid>
              <Grid
                className='team_content'
                style={{
                  maxWidth: '500px',
                }}
              >
                {employee.description}
              </Grid>
            </Grid>
            {expandedItemIndex === employee.id ? (
              <Grid
                onClick={() => toggleExpand(employee.id)}
                container
                className='read-more-btn'
                style={{
                  fontStyle: 'italic',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginBottom: '20px',
                }}
              >
                <p style={{ fontWeight: '300' }} className='team_content'>
                  Pokaż mniej
                </p>
                <ExpandLessIcon
                  fontSize='large'
                  style={{ marginTop: '-20px' }}
                />
              </Grid>
            ) : (
              <Grid
                container
                onClick={() => toggleExpand(employee.id)}
                className='read-more-btn'
                style={{
                  fontStyle: 'italic',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginBottom: '20px',
                }}
              >
                <p style={{ fontWeight: '300' }} className='team_content'>
                  Pokaż więcej
                </p>
                <ExpandMoreIcon
                  fontSize='large'
                  style={{ marginTop: '-20px' }}
                />
              </Grid>
            )}
          </Grid>
        ))}
    </Grid>
  );
}

const employees: Employee[] = [
  {
    id: 3,
    name: 'Klaudia Krzelowska',
    description: (
      <>
        <p>
          Jestem psychologiem w trakcie szkolenia psychoterapeutycznego.Prowadzę
          konsultacje psychologiczne dla dorosłych i młodzieży od 16 roku życia,
          zmagających się z zaburzeniami zdrowia psychicznego oraz kryzysami
          życiowymi i trudnościami w funkcjonowaniu. W swojej praktyce zawodowej
          kieruję się przede wszystkim dobrem i bezpieczeństwem pacjenta,
          pracując w oparciu o Kodeks Etyczny Psychologa, poddając swoją pracę
          regularnej superwizji oraz uczestnicząc w szkoleniach poszerzających
          moje kompetencje.
        </p>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Wykształcenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={SchoolIcon}
            list={[
              'Psychologia - Uniwersytet Jagielloński w Krakowie',
              'W trakcie kursu psychoterapii w nurcie psychodynamicznym i systemowym',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Doświadczenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={ApartmentIcon}
            list={[
              'Poradnia Zdrowia Psychicznego w Myślenicach',
              'Środowiskowy Dom Samopomocy Krakowskiej Fundacji Hamlet',
              'Oddział psychiatryczny Kraków- Krowodrza i Kraków- Podgórze I Szpitala Klinicznego im. dr. Józefa Babińskiego w Krakowie',
              'Oddział Kliniczny Psychiatrii Dorosłych, Dzieci i Młodzieży Szpitala Uniwersyteckiego w Krakowie',
              'Oddział Dzienny w Myślenicach Szpitala Klinicznego im. dr. Józefa Babińskiego',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Dla kogo?
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={PersonIcon}
            list={['Przyjmuję osoby powyżej 16 roku życia']}
          />
        </div>
      </>
    ),
    position: 'Psycholog, Psychoterapeuta (w trakcie szkolenia)',
    imageUrl: require('../uploads/Employees/Klaudia_Krzelowska.png'),
  },
  {
    id: 1,
    name: 'Tatiana Inglot-Płoskonka',
    description: (
      <>
        <p>
          Jestem psychologiem, psychoterapeutą.Prowadzę psychoterapię
          indywidualną osób dorosłych w nurcie systemowo - psychodynamicznym.
          Swoją pracę poddaję regularnej superwizji. Oferuję swoją pomoc głównie
          osobom z zaburzeniami lękowymi, psychosomatycznymi, z zaburzeniami
          nastroju, w depresji, w kryzysach emocjonalnych.
        </p>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Wykształcenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={SchoolIcon}
            list={[
              'Psychologia - Uniwersytet Jagielloński w Krakowie',
              'Całościowe szkolenie przygotowujące do certyfikatu psychoterapeuty PTP. Szkolenie systemowo - psychodynamiczne. Podejście zintegrowane. - Katedra Psychiatrii CM UJ w Krakowie',
              'Studium Pedagogiczne',
              'Szkolenie pt.: „Umiejętność stosowania i interpretacji Testu Drzewa”',
              'Szkolenie z zakresu podstaw diagnozy neuropsychologicznej pt.: „Mózg a Człowiek”',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Doświadczenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={ApartmentIcon}
            list={[
              'Zakład Opiekuńczo-Leczniczy w Krakowie',
              'Szpital Kliniczny im. dr Józefa Babińskiego SP ZOZ w Krakowie - Zespół Leczenia Środowiskowego',
              'Szpital Kliniczny im. dr Józefa Babińskiego SP ZOZ w Krakowie- Centrum Zdrowia Psychicznego',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Dla kogo?
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={PersonIcon}
            list={['Przyjmuję osoby powyżej 18 roku życia']}
          />
        </div>
      </>
    ),
    position: 'Psycholog, Psychoterapeuta',
    imageUrl: require('../uploads/Employees/Tatiana_Inglot_Płoskonka.png'),
  },
  // {
  //   id: 8,
  //   name: 'Magdalena Bednarczyk',
  //   description: (
  //     <>
  //       <p>
  //         Jestem psychologiem z pasją do pomagania ludziom i wspierania ich
  //         rozwoju.W swojej pracy pomagam starszej młodzieży i osobom dorosłym,
  //         oferując wsparcie w obszarach takich jak radzenie sobie ze stresem,
  //         trudności emocjonalne czy wyzwania życiowe. Bardzo ważne jest dla mnie
  //         indywidualne podejście oraz stworzenie bezpiecznej przestrzeni do
  //         rozmowy i zrozumienia. Jestem psychologiem zarówno empatycznie
  //         słuchającym, jak i aktywnie zaangażowanym w proces pomagania.
  //         Serdecznie zapraszam na konsultację.
  //       </p>
  //       <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
  //         Wykształcenie
  //       </p>
  //       <div style={{ marginBottom: '1rem' }}>
  //         <ListComponent
  //           key={2}
  //           icon={SchoolIcon}
  //           list={['Psychologia - Uniwersytet Jagielloński w Krakowie']}
  //         />
  //       </div>
  //       <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
  //         Doświadczenie
  //       </p>
  //       <div style={{ marginBottom: '1rem' }}>
  //         <ListComponent
  //           key={2}
  //           icon={ApartmentIcon}
  //           list={[
  //             'Przedszkole Misie Pysie Kraków',
  //             'Żłobek W Stumilowym Lesie',
  //             'Szpital Kliniczny im. dr Józefa Babińskiego w Krakowie',
  //           ]}
  //         />
  //       </div>
  //       <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
  //         Dla kogo?
  //       </p>
  //       <div style={{ marginBottom: '1rem' }}>
  //         <ListComponent
  //           key={2}
  //           icon={PersonIcon}
  //           list={['Przyjmuję osoby powyżej 16 roku życia']}
  //         />
  //       </div>
  //     </>
  //   ),
  //   position: 'Psycholog',
  //   imageUrl: require('../uploads/Employees/Magdalena_Bednarczyk.png'),
  // },
  {
    id: 6,
    name: 'Aleksandra Tyrna',
    description: (
      <>
        <p>
          Jestem psychologiem w trakcie szkolenia psychoterapeutycznego. W
          swojej pracy oferuję bezpieczną oraz wspierającą przestrzeń, w której
          każdy może poczuć się zrozumiany i wysłuchany, co jest kluczowym
          elementem skutecznej pomocy.Ukończyłam również liczne szkolenia m.in.
          Specyfika pracy psychologa, Seksuologia w ICD-11, Zaburzenia
          osobowości i rozwój osobowości w terapii psychodynamicznej, które
          pozwoliły mi podnieść swoje kwalifikacje. We wspomnianych instytucjach
          miałam okazję pracować z osobami zmagającymi się z różnorodnymi
          trudnościami psychicznymi - z dziećmi, nastolatkami oraz osobami
          dorosłymi.
        </p>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Wykształcenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={SchoolIcon}
            list={[
              'Psychologia - Uniwersytet Komisji Edukacji Narodowej w Krakowie',
              'Mediacje i negocjacje - Uniwersytet Pedagogiczny im. KEN w Krakowie',
              'Podstawy psychoterapii - Uniwersytet Jagielloński w Krakowie',
              'W trakcie szkolenia psychoterapeutycznego',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Doświadczenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={ApartmentIcon}
            list={[
              'Centrum Zdrowia Psychicznego - Szpital w Cieszynie',
              'Poradnia Psychologiczno-Pedagogiczna w Bielsku-Białej',
              'Wydział Psychoterapii UJ',
              'Centrum Terapii Empatyczni',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Dla kogo?
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={PersonIcon}
            list={['Przyjmuję osoby powyżej 12 roku życia']}
          />
        </div>
      </>
    ),
    position: 'Psycholog, Psychoterapeuta (w trakcie szkolenia)',
    imageUrl: require('../uploads/Employees/Aleksandra_Tyrna.png'),
  },
  {
    id: 5,
    name: 'Julia Sobik',
    description: (
      <>
        <p>
          Jestem psychologiem. Pracuję z dziećmi, młodzieżą oraz osobami
          dorosłymi. W swojej praktyce wykorzystuję Terapię Skoncentrowaną na
          Rozwiązaniach (TSR), która pomaga zarówno dzieciom, jak i ich
          rodzicom, odnajdywać indywidualne drogi do rozwiązywania problemów i
          radzenia sobie z wyzwaniami. Często korzystam również z podejścia
          poznawczo- behawioralnego (CBT), które wspiera młodych ludzi w
          zrozumieniu wpływu myśli na emocje i zachowania, co umożliwia
          wprowadzenie konkretnych i pozytywnych zmian w ich życiu.
        </p>
        <p>
          Posiadam doświadczenie w pracy polegającej na wspieraniu rozwoju
          społecznego, emocjonalnego oraz poznawczego dzieci ze spektrum autyzmu
          oraz z różnego rodzaju niepełnosprawnościami. W pracy z dorosłymi
          stawiam na otwartość, empatię i dialog. Pomagam również młodym osobom
          rozwijać zdrowe strategie radzenia sobie ze stresem, trudnymi emocjami
          oraz konfliktami, wspierając ich w budowaniu pewności siebie i
          poprawie relacji z otoczeniem. Każdego traktuję indywidualnie,
          dostosowując metody pracy do jego potrzeb. Zapraszam osoby dorosłe,
          rodziców z dziećmi od 7. roku życia oraz młodzież do konsultacji,
          oferując profesjonalne i empatyczne wsparcie.
        </p>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Wykształcenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={SchoolIcon}
            list={[
              'Psychologia - Uniwersytet Jagielloński w Krakowie',
              'Terapia Skoncentrowana na Rozwiązaniach',
              'Podstawy interwencji kryzysowej w kontekście bycia konsultantem telefonu wsparcia',
              'Trener TUS I i II ',
              'Szkolenie integracja sensoryczna',
              'Szkolenie jak radzić sobie z deskrukcyjnym zachowaniem dziecka',
              'Kurs komunikacja interpersonalna',
              'Szkolenie "diagnoza różnicowa u dzieci: ASD, zaburzenia więzi, nieprawidłowo kształtująca się osobowość"',
              'Szkolenie "lęk i fobie szkolne"',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Doświadczenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={ApartmentIcon}
            list={[
              'Świadczenie specjalistycznych usług dla rodzin z dzieckiem z niepełnosprawnością',
              'Specjalistyczny szpital im. Ludwika Rydygiera w Krakowie - Oddział Psychiatrii',
              'Telefon Wsparcia Rozmowy Bez Presji',
              'Koordynatorka Telefonu Wsparcia',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Dla kogo?
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={PersonIcon}
            list={['Przyjmuję osoby powyżej 7 roku życia']}
          />
        </div>
      </>
    ),
    position: 'Psycholog',
    imageUrl: require('../uploads/Employees/Julia_Sobik.png'),
  },
  {
    id: 7,
    name: 'Zuzanna Sulich',
    description: (
      <>
        <p>
          Jestem psychologiem w trakcie szkolenia psychoterapeutycznego.
          Specjalizuję się w pracy z dziećmi, młodzieżą oraz młodymi dorosłymi.
          Swoje kompetencje rozwijam w podejściu Terapii Skoncentrowanej na
          Rozwiązaniach w ramach Studium. Obecnie jestem w trakcie studiów
          podyplomowych z przygotowania pedagogicznego. Jestem certyfikowanym
          Trenerem Umiejętności Społecznych.W swojej pracy stawiam na otwartość,
          empatię i szacunek. Oferuję konsultacje terapeutyczne, poradnictwo
          psychologiczne oraz psychoedukację, wspierając pacjentów w trudnych
          momentach życia. Pomagam w radzeniu sobie ze stresem oraz wspieram w
          budowaniu odporności psychicznej, pewności siebie i asertywności,
          uwzględniając indywidualne potrzeby każdego pacjenta.
        </p>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Wykształcenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={SchoolIcon}
            list={[
              'Psychologia - Krakowska Akademia im.A. Frycza-Modrzewskiego',
              'Studium Terapii i Podejścia Skoncentrowanego Na Rozwiązaniach',
              'Szkoła Terapii Poznawczo-Behawioralnej Centrum CBT-EDU',
              'W trakcie szkolenia psychoterapeutycznego',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Doświadczenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={ApartmentIcon}
            list={[
              'Psycholog NZOZ Magmed Wojewódzki Ośrodek Rehabilitacji Dzieci i Dorosłych',
              'Szpital Uniwersytecki w Krakowie - Oddział Psychiatrii Dzieci i Młodzieży',
              'Centrum Opieki i Rehabilitacji Dom pod Sosnami',
              'Przedszkole Fair Play',
              'Szpital Uniwersytecki w Krakowie -Oddział Kliniczny Psychiatrii Dorosłych',
              'Szpital Kliniczny im.dr. Józefa Babińskiego w Krakowie - Oddział Psychiatryczny',
              'Powiatowe Centrum Pomocy Rodzinie w Wieliczce',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Dla kogo?
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={PersonIcon}
            list={['Przyjmuję osoby powyżej 7 roku życia']}
          />
        </div>
      </>
    ),
    position: 'Psycholog, Psychoterapeuta (w trakcie szkolenia)',
    imageUrl: require('../uploads/Employees/Zuzanna_Sulich.png'),
  },
  // {
  //   id: 3,
  //   name: 'Anna Ulatowska',
  //   description: (
  //     <>
  //       <p>
  //         Jestem psycholożką. Pracuję z dziećmi od 7. roku życia, młodzieżą oraz
  //         osobami dorosłymi. Moim celem jest wsparcie dzieci i młodzieży oraz
  //         ich rodziców w radzeniu sobie z trudnościami emocjonalnymi, szkolnymi,
  //         czy społecznymi. Każde dziecko to dla mnie wyjątkowa historia, dlatego
  //         w swojej pracy podchodzę indywidualnie do potrzeb i wyzwań młodych
  //         pacjentów.
  //       </p>
  //       <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
  //         Wykształcenie
  //       </p>
  //       <div style={{ marginBottom: '1rem' }}>
  //         <ListComponent
  //           key={2}
  //           icon={SchoolIcon}
  //           list={[
  //             'Psychologia - Uniwersytet SWPS, Sopot',
  //             'Kurs I stopnia Interwencji Kryzysowej',
  //             'Kurs Terapii Skoncentrowanej na Rozwiązaniach',
  //           ]}
  //         />
  //       </div>
  //       <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
  //         Doświadczenie
  //       </p>
  //       <div style={{ marginBottom: '1rem' }}>
  //         <ListComponent
  //           key={2}
  //           icon={ApartmentIcon}
  //           list={[
  //             'Warsztaty Terapii Zajęciowej w Myślenicach',
  //             'ANON Centrum Psychoterapii i Leczenia Uzależnień w Koszalinie',
  //             'Ośrodek Interwencji Kryzysowej w Wadowicach',
  //           ]}
  //         />
  //       </div>
  //       <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
  //         Dla kogo?
  //       </p>
  //       <div style={{ marginBottom: '1rem' }}>
  //         <ListComponent
  //           key={2}
  //           icon={PersonIcon}
  //           list={['Przyjmuję osoby powyżej 7 roku życia']}
  //         />
  //       </div>
  //     </>
  //   ),
  //   position: 'Psycholog',
  //   imageUrl: require('../uploads/Employees/Ania_Ulatowska.png'),
  // },
  {
    id: 2,
    name: 'Agnieszka Czeczenikow',
    description: (
      <>
        <p>
          Jestem psychoterapeutą. Prowadzę psychoterapię w nurcie integratywnym.
          Stosuję metody dobrane do potrzeb pacjenta: wglądowe, poszerzające
          samoświadomość, techniki pracy z ciałem, oddechem, mindfullness, pracę
          z systemem rodzinnym. Pomagam osobom od 13 roku życia. Pracuję także z
          parami. Prowadzę terapię krótko i długoterminową, w zależności od
          indywidualnych potrzeb. Pracuję z osobami: z depresją, uczuciem
          przygnębienia, brakiem energii, myślami rezygnacyjnymi, poczuciem
          bezsensu, osobami doświadczającymi lęku, stresu, napięcia, gonitwy
          myśli, z niepokojem, trudnościami ze snem, trudnościami w kontaktach
          społecznych. Pomagam w problemach życiowych, kryzysach małżeńskich,
          trudnościach rodzicielskich. Prowadzę psychoterapię par.
        </p>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Wykształcenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={SchoolIcon}
            list={[
              'Etnologia - Uniwersytet Jagielloński w Krakowie',
              'Studium rozwoju osobistego - Szkoła trenerów komunikacji opartej na empatii',
              'Szkolenie - Psychoterapia par, praca psychoterapeuty w służbie miłości',
              'Szkolenie - Praca z umysłem, w integratywnej psychoterapii systemowej',
              'Szkolenie - Integratywna systemowa psychoterapia zaburzeń lękowych i postraumatycznych',
              'Szkolenie - Integratywna systemowa psychoterapia zaburzeń depresyjnych',
              'W trakcie szkolenia - Całościowe Studium Psychoterapii',
              'Certyfikowany Edukator Pozytywnej Dyscypliny',
              'Trener Umiejętności Społecznych',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Doświadczenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={ApartmentIcon}
            list={[
              'Centrum Usług Społecznych w Myślenicach - indywidualny psychoterapeuta',
              'Ośrodek Interwencji Kryzysowej w Wieliczce - psychoterapeuta/ interwent kryzysowy',
              'Szpital Kliniczny im. dr. Józefa Babińskiego w Krakowie',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Dla kogo?
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={PersonIcon}
            list={['Przyjmuję osoby powyżej 13 roku życia']}
          />
        </div>
      </>
    ),
    position: 'Psychoterapeuta',
    imageUrl: require('../uploads/Employees/Agnieszka_Czeczenikow.png'),
  },
  {
    id: 9,
    name: 'Aneta Maj-Cygal',
    description: (
      <>
        <p>
          Jestem pedagogiem oraz terapeutą z 15 letnim stażem pracy. Oferuję
          pomoc psychologiczną dla dzieci i dorosłych. W gabinecie pracuje z
          dziećmi i młodzieżą od 7 roku życia które mają trudności w
          komunikacji, wyrażaniu emocji, niskiej samoocenie, poczuciu pustki,
          obniżonym nastroju, mają problemy rówieśnicze czy szkolne. Pracuje
          również z rodzicami. Oferuję także interwencję kryzysową dla osób
          dorosłych i dzieci oraz konsultacje terapeutyczne dla rodzin.
        </p>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Wykształcenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={SchoolIcon}
            list={[
              'Pomoc psychologiczna i interwencja kryzysowa  dla osób w kryzysie i traumie - Niepubliczna Placówka Kształcenia Ustawicznego Psychodia',
              'Pedagogika Resocjalizacyjna - Staropolska Akademia Nauk Stosowanych w Kielcach',
              'Kurs Kwalifikacyjny z zakresu przygotowania pedagogicznego dla czynnych zawodowo nauczycieli - Centrum Szkolenia PROTOTO we Wrocławiu',
              'Terapia Rodzin i Par - Wyższa Szkoła Kształcenia Zawodowego',
              'Organizacja i zarządzanie jednostkami pomocy społecznej - Wyższa Szkoła Kształcenia Zawodowego',
              'Szkolenie - metody pracy w interwencji kryzysowej- Terapia Skoncentrowana na Rozwiązaniach',
              'Szkolenie - jak dbać o relacje wśród uczniów - przeciwdziałanie hejtowi i nękaniu',
              'Szkolenie - dlaczego dopalacze są tak niebezpieczne',
              'Szkolenie - substancje psychoaktywne, niebezpieczeństwo wśród młodzieży',
              'Szkolenie - normy i zasady ochrony małoletnich',
              'Szkolenie - odpowiedzialność prawna wychowawców i pedagogów',
              'Szkolenie - przemoc rówieśnicza z perspektywy psychologicznej',
              'Szkolenie - działania wspomagające ucznia z trudnościami w uczeniu się',
              'Szkolenie - program profilaktyki selektywnej dzieci i młodzieży',
              'Szkolenie - problematyka zachowań autodestrukcyjnych, w tym suicydalnych u dzieci i młodzieży',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Doświadczenie
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={ApartmentIcon}
            list={[
              'Placówka opiekuńczo - wychowawcza typu socjalizacyjnego "Moja Rodzina" - wychowawca',
              'Niepubliczny Specjalistyczny Punkt Przedszkolny "Teraz My" - nauczyciel',
              'Caritas Archidiecezji Krakowskiej - Placówka opiekuńczo - wychowawcza typu socjalizacyjnego - pedagog/wychowawca',
              'Ośrodek Interwencji Kryzysowej w Wieliczce - pedagog/ interwent kryzysowy',
            ]}
          />
        </div>
        <p className='title' style={{ fontSize: '1rem', textAlign: 'center' }}>
          Dla kogo?
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            key={2}
            icon={PersonIcon}
            list={['Przyjmuję osoby powyżej 7 roku życia']}
          />
        </div>
      </>
    ),
    position: 'Pedagog, Interwent kryzysowy, Terapeuta rodzin i par',
    imageUrl: require('../uploads/Employees/Aneta_Maj_Cygal.png'),
  },
];
